const newPasswordService = http => {
  const endpoint = `${process.env.VUE_APP_API_BASE_URL}auth/reset-password`
  const endpointVerificarToken = `${process.env.VUE_APP_API_BASE_URL}auth/check-token`

  const post = data => http.post(endpoint, data)

  const checkToken = data => http.post(endpointVerificarToken, data)

  return {
    checkToken,
    post,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  newPasswordService,
}
